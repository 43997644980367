import React from 'react'

import { eventService } from '../services/event.service'

export const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>, setFileList: any, setSelectedFiles: any) => {
    if (e.target.files){
        const selectedFiles = Array.from(e.target.files);
        setFileList(selectedFiles); 
        setSelectedFiles(selectedFiles as File[]); 
    }
}


export const handleFinalSubmit = async (
    userInputs: any, 
    eventId: string | undefined, 
    groupId: string | undefined, 
    uploadedImages: never[] | undefined, 
    inviteType: string | undefined, 
    navigate: any, 
    setBtnLoading: (loading: boolean) => void) => {

    const request = {
        event_id: eventId,
        group_id: groupId,
        document_urls: uploadedImages,
        message: userInputs.message,
        email: userInputs.email,
        name: userInputs.name,
        invite_type: inviteType
    }

    await eventService.uploadDocuments(request)
        .then(() => {
            setBtnLoading(false)
            navigate('/upload-success')
        })
        .catch(() => {
            setBtnLoading(false)
        })
}

