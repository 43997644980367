import Uploader from '../hooks/Uploader'
import {eventService} from '../services/event.service'


export const sentGreetingsToRecipient = async (eventid: string, setShowResendSuccessDialog: (show: boolean) => void) => {
    const request = {
        event_id: eventid
    }
    await eventService.sendGreetings(request)
        .then(() => {
            setShowResendSuccessDialog(true)
        })
}

export const handleSaveMedia = async (
    data: any,
    files: any[],
    props: any, 
    setShowResendSuccessDialog: (dialog: boolean) => void, 
    setUploadStarted: (upload: boolean) => void, 
    setBtnLoading: (loading: boolean) => void,
    setShowSuccessDialog: (success: boolean) => void,
    setErrorMessage: (message: string) => void) => {
        
    setErrorMessage('');
    setBtnLoading(true);
    if (!data.message && files.length === 0) {
        setErrorMessage("Message or video required to send");
        setBtnLoading(false);
        return;
    }
    try {
        if(data.message && files.length === 0){
            setBtnLoading(true);
            const request = {
                recipient_name: data?.name,
                recipient_email: data?.email,
                message: data?.message,
                publish_video_url: null
            };

       await eventService.eventUpdate(props?.event.event_id, request);
        await sentGreetingsToRecipient(props?.event.event_id, () => {});
        props?.updateEventDataCallBack();
        setShowSuccessDialog(true); 
        }
        if (files.length > 0) {
            setUploadStarted(true);

            const file = files[0];
            const fileName = file.name;
            const uploader = new Uploader({ fileName, file });
            await uploader.start();
            const s3Url = uploader.getS3Url();
            const request = {
                recipient_name: data?.name,
                recipient_email: data?.email,
                message: data?.message,
                publish_video_url: s3Url
            };
            await eventService.eventUpdate(props?.event.event_id, request);
            setShowSuccessDialog(true);
            sentGreetingsToRecipient(props?.event.event_id, () => {});
            props?.updateEventDataCallBack();
            setErrorMessage('');
            }
        } catch (error: any) {
            if (error?.response?.data?.recipient_email[0]) {
                setErrorMessage(error.response.data.recipient_email[0]);
            } else {
                setErrorMessage('An error occurred. Please try again later.');
            }
        }
        finally {
            setUploadStarted(false);
            setBtnLoading(false);
        }
}