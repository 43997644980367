import { useState } from 'react'
 
import {
    Box,
    Card,
    Checkbox,
    Chip,
    Grid,
    Hidden,
    Tooltip,
    Typography
} from '@mui/material'
 
import { ReactComponent as BellIcon } from '../../../../assets/bell-solid.svg'
import { ReactComponent as RemainderSuccessIcon } from '../../../../assets/remainder-success-icon.svg'
import CustomLoadingButton from '../../../../components/Shared/Button/CustomLoadingButton'
import CustomizedDialogs from '../../../../components/Shared/Dialogs/CustomizedDialogs'
import DialogSuccess from '../../../../components/Shared/Dialogs/DialogSuccess'
import NoDataLabel from '../../../../components/Shared/Label/NoDataLabel'
import {eventService} from '../../../../services/event.service'


interface Invitee {
    email: string;
    name: string;
  }
 
function ListInvitees(props: any) {
    const [selectedInvitees, setSelectedInvitees] = useState<Invitee[]>([]);
    const [showSuccessDialog, setShowSuccessDialog] = useState(false)
    const [btnLoading, setBtnLoading] = useState(false)

const handleChange = (email: string, name: string) => {
  let newArray;
  const isSelected = selectedInvitees.find((item) => item.email ===  email);
  if (!isSelected) {
    newArray = [...selectedInvitees, { email, name }];
  } else {
    newArray = selectedInvitees.filter((item) => item.email !== email);
  }
  setSelectedInvitees(newArray);
};
    const handleSendReminder = async () => {
        const filteredInvitees = selectedInvitees.filter((item) => item.email !== "" && item.email !== null);
        const invitee: any[] = [];
        filteredInvitees.forEach((val) => {
          invitee.push({ email: val.email, name:val.name, group_id: null });
        });
     
        if (filteredInvitees.length > 0) {
          setBtnLoading(true);
          const request = {
            invitees: invitee,
            event_id: props?.event.event_id
          };
          try {
            await eventService.sendRemainderEmail(request);
            setSelectedInvitees([]);
            setShowSuccessDialog(true);
            setBtnLoading(false);
          } catch (error) {
            setBtnLoading(false);
          }
        }
      };

    return (
        <>
            {props?.eventInvites && props?.eventInvites?.invites?.length > 0 ? 
            (props?.eventInvites?.invites?.map((invitee: any) => (
                <Card
                    key={invitee.email}
                    sx={{
                        boxShadow: '0px 0px 30px #0000000D',
                        border: '1px solid',
                        borderColor: 'secondary.contrastText',
                        borderRadius: '4px',
                        m: 2
                    }}
                >
                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Grid item xs={1.5} sm={1}>
                            <Checkbox
                                value={invitee.email}
                                onChange={() => handleChange(invitee.email, invitee.username)}
                            />
                        </Grid>
 
                        <Hidden only="xs">
                            <Grid item xs={6} sm={3}>
                                <Typography
                                    variant="subtitle1"
                                    sx={{
                                        font: 'normal normal normal 13px/14px Inter',
                                        color: 'custom.dark',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        display: '-webkit-box',
                                        WebkitLineClamp: '1 !important',
                                        WebkitBoxOrient: 'vertical' 
                                    }}
                                >
                                    {invitee.username}
                                </Typography>
                            </Grid>
                        </Hidden>
 
                        <Grid item xs={6} sm={5}>
                            <Tooltip title={invitee.email} placement='top'> 
                                <Typography
                                    variant="subtitle1"
                                    sx={{
                                        font: 'normal normal normal 11px/14px Inter',
                                        color: '#8D8D8D',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        display: '-webkit-box',
                                        WebkitLineClamp: '1 !important',
                                        WebkitBoxOrient: 'vertical',
                                        maxWidth: '100%'
                                    }}
                                >
                                   {invitee.email}
                                </Typography>
                                </Tooltip>
                        </Grid>
 
                        <Grid item xs={4.5} sm={3}>
                            {invitee.status === 'False' ? (
                                <Chip
                                    label="Invited"
                                    variant="outlined"
                                    sx={{
                                        font: 'normal normal medium 11px/14px Inter',
                                        color: 'secondary.main',
                                        bgcolor: 'secondary.contrastText',
                                        borderColor: 'secondary.contrastText',
                                        borderRadius: '4px',
                                        height: '26px'
                                    }}
                                />
                            ) : invitee.is_documents_uploaded ? (
                                <Chip
                                    label="Submitted"
                                    variant="outlined"
                                    sx={{
                                        font: 'normal normal medium 11px/14px Inter',
                                        color: '#7FD14D',
                                        bgcolor: '#f2faed',
                                        borderColor: '#f2faed',
                                        borderRadius: '4px',
                                        height: '26px'
                                    }}
                                />
                            ) : (
                                <Chip
                                    label="Reminder"
                                    variant="outlined"
                                    sx={{
                                        font: 'normal normal medium 11px/14px Inter',
                                        color: '#FF9E00',
                                        bgcolor: '#fff5e5',
                                        borderColor: '#fff5e5',
                                        borderRadius: '4px',
                                        height: '26px'
                                    }}
                                />
                            )}
                        </Grid>
                    </Grid>
                </Card>
            ))): (
                <NoDataLabel />
            )}
            {props?.eventInvites?.invites?.length > 0 && (
                <>
                    <Box sx={{ textAlign: 'right', m: 2 }}>
                        <CustomLoadingButton
                            startIcon={<BellIcon />}
                            onClick={handleSendReminder}
                            loading={btnLoading}
                            name="Send Reminders"
                            width="auto"
                        />
                    </Box>
 
                    <CustomizedDialogs
                        open={showSuccessDialog}
                        onClose={() => setShowSuccessDialog(false)}
                        header="Reminder Sent Successfully"
                        message="The reminders to the invitees are on their way."
                        continueBtn="Manage Invitees"
                        icon={RemainderSuccessIcon}
                        component={DialogSuccess}
                    />
                </>
            )}

 
         
        </>
    )}
export { ListInvitees }