import React, {useState, useEffect, useCallback} from 'react';
 
import { Grid, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import moment from 'moment'
 
import BirthdayCakeIcon from '../../assets/birthday-cake.svg'
import EventViewHeaderCardBg from '../../assets/EventViewHeaderCardBg.svg'
import UserIcon from '../../assets/user.svg'
import { EventTimer } from '../../components/Shared/Timer'
 
function HeaderCard(props: any) {

    const calculateTimeLeft = useCallback(() => {
        const eventDate = moment(props?.event?.deadline)
        const now = moment()
        const deadline = now.isBefore(eventDate) ? eventDate : now.clone().endOf('day')
        const duration = moment.duration(deadline.diff(now))
        return {
            hours: duration.hours(),
            days: duration.days(),
            minutes: duration.minutes(),
            seconds: duration.seconds()
        }
    }, [props?.event?.deadline])
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft())
    
        useEffect(() => {
            const interval = setInterval(() => {
                setTimeLeft(calculateTimeLeft())
            }, 1000)
     
            return () => clearInterval(interval)
        }, [calculateTimeLeft])
 
    return (
        <Box
            sx={{
                backgroundColor: 'primary.light',
                borderRadius: { xs: '0', sm: '4px' }
            }}
        >
            <Card
                sx={{
                    display: 'flex',
                    background: `transparent url(${EventViewHeaderCardBg}) 0% 0% no-repeat padding-box`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    height: { xs: '400px', sm: '200px' },
                    width: '100%',
                    borderRadius: '4px'
                }}
            >
                <Grid
                    container
                    component="main"
                    direction="row"
                    justifyContent="space-between"
                    sx={{
                        height: { xs: '20px', sm: '100vh' },
                        width: '100%'
                    }}
                >
                    <Grid item xs={12} sm={6}>
                        <Box
                            sx={{
                                padding: '40px 30px',
                                width: '100%'
                            }}
                        >
                            <Grid
                                container
                                component="main"
                                direction="row"
                                alignItems="center"
                                justifyContent="space-between"
                                spacing={3}
                            >
                                <Grid item xs={4} md={3.5}>
                                    <Box
                                        component="div"
                                        sx={{
                                            width: '100px',
                                            height: '100px',
                                            border: '2px solid white',
                                            borderRadius: '50px',
                                            background: `url(${
                                                props?.event
                                                    ?.event_image_url !== '' &&
                                                props?.event
                                                    ?.event_image_url !== null
                                                    ? props?.event
                                                          ?.event_image_url
                                                    : UserIcon
                                            })`,
                                            backgroundRepeat: 'no-repeat',
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center'
                                        }}
                                    />
                                </Grid>
 
                                <Grid item xs={8} md={8.5} sx={{ pt: 2 }}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            mb: 1
                                        }}
                                    >
                                        <Box
                                            component="img"
                                            src={BirthdayCakeIcon}
                                            sx={{
                                                height: '30px',
                                                width: '30px',
                                                display: 'inline-block',
                                                mr: 1
                                            }}
                                        />
 
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                bgcolor: '#ffffff99',
                                                borderRadius: '4px'
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    font: 'normal normal normal 11px/14px Inter',
                                                    display: 'inline-block',
                                                    p: 0.5,
                                                    m: 'auto'
                                                }}
                                            >
                                                {moment(
                                                    props?.event?.event_date
                                                ).format('MM-DD-YYYY')}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Typography
                                        sx={{
                                            font: 'normal normal 600 19px/24px Inter',
                                            color: 'white',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            display: '-webkit-box',
                                            WebkitLineClamp: '1 !important',
                                            WebkitBoxOrient: 'vertical' 
                                        }}
                                    >
                                        {props?.event.name}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
 
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        justifyItems="right"
                        justifyContent="right"
                    >
                        <Card
                            sx={{
                                width: '250px',
                                margin: {
                                    xs: 'auto',
                                    sm: '30px 30px auto auto'
                                },
                                bgcolor: '#08387490'
                            }}
                        >
                            <Box
                                sx={{
                                    width: '100%',
                                    bgcolor: 'primary.main',
                                    p: 1
                                }}
                            >
                                <Typography variant="body2">
                                    Time left to upload your files
                                </Typography>
                            </Box>
 
                            <Box
                                sx={{
                                    px: 3,
                                    py: 2
                                }}
                            >
                                <EventTimer expiryTimestamp={timeLeft} />
                            </Box>
                        </Card>
                    </Grid>
                </Grid>
            </Card>
        </Box>
    )
}
 
export { HeaderCard }
 