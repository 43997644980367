import { useEffect, useState } from 'react'


import { useAuth } from '@clerk/clerk-react'
import { AppBar, Box, CssBaseline, Toolbar } from '@mui/material'
import { useNavigate, useLocation  } from 'react-router-dom'

import MobileOnlyMenu from './MobileOnly/MobileOnlyMenu'
import ProfileSettings from './ProfileSettings'
import SmUpMenu from './SmUp/SmUpMenu'
import Logo from '../../assets/logo.svg'

export default function Navbar() {
    const navigate = useNavigate()
    const location = useLocation();
    const { isSignedIn } = useAuth()
    const [currentLink, setCurrentLink] = useState(
        window.location.pathname === '/' ? '/' : window.location.pathname
    )
    const handleNavigationChange = (nav: any) => {
        setCurrentLink(nav?.value)
       
        if (nav.value){
            if(location.pathname === '/'){
                window.scrollTo(0, 0);
            }
        }
        if (nav?.isSingleNavigation && nav.value.includes('#')) {
            window.location.href = `/${nav.value}`
        } else {
            navigate(nav.value)
        }
    }

    const handleRedirectHome = async () => {
        window.scrollTo(0, 0);
        if (isSignedIn) {
            navigate('/')
        }else if (window.location.href.includes('#')) {
            window.location.href = '/'
            setCurrentLink('/')
        } 
        else {
            navigate('/')
            setCurrentLink('/')
        }
    }


    useEffect(() => {
        const urls = location.pathname.split('/')
        setCurrentLink(
            urls[urls.length - 1] === '/' ? '/' : urls[urls.length - 1]
        )
        if (location.pathname.includes('#')) {
            window.location.href = location.pathname
        }
        
    }, [location.pathname])

    useEffect(() => {
        const handleBackNavigation = () => {
            localStorage.removeItem('redirectPath')
        }
        window.addEventListener('popstate', handleBackNavigation);

        return () => {
            window.addEventListener('popstate', handleBackNavigation);
        }
    }, [])

    if (location.pathname === '/login') return null

    return (
        <Box sx={{ display: 'flex', mb: 15 }}>
            <CssBaseline />
            <AppBar
                component="nav"
                sx={{
                    bgcolor: 'white',
                    boxShadow: '0px 0px 30px #00000020',
                    color: 'black',
                    py: 1
                }}
            >
                <Toolbar>
                    <Box component="div" sx={{ flexGrow: 1 }}>
                        <Box
                            component="img"
                            src={Logo}
                            style={{ flexGrow: 1 }}
                            onClick={handleRedirectHome}
                            sx={{ flexGrow: 1, cursor:'pointer' }}
                        />
                    </Box>

                    <SmUpMenu
                        Logo={Logo}
                        handleRedirectHome={handleRedirectHome}
                        handleNavigationChange={handleNavigationChange}
                        currentLink={currentLink}
                    />

                    <MobileOnlyMenu
                        setCurrentLink={setCurrentLink}
                        currentLink={currentLink}
                    />

                    <ProfileSettings />
                </Toolbar>
            </AppBar>
        </Box>
    )
}
