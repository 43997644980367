import {
    Box,
    Grid,
    Hidden,
    Link,
    Typography,
    useMediaQuery
} from '@mui/material'
import { useNavigate } from 'react-router-dom'


import { ReactComponent as FacebookIcon } from '../../../assets/Home/facebook-icon.svg'
import { ReactComponent as Instagram } from '../../../assets/Home/instagram-icon.svg'
import { ReactComponent as TwitterIcon } from '../../../assets/Home/twitter-icon.svg'
import Logo from '../../../assets/logo.svg'
import theme from '../../../styles/theme'

const navLinks = [
    { value: '/', label: 'Home' },
    { value: '#about-us', label: 'About Us' },
    { value: '#how-it-works', label: 'How It works' },
    { value: 'login', label: 'Sign In / Sign Up' },
    { value: '/create_event', label: 'Create Event', requiresLogin: true }
]

const contactNavLink = [
    { value: '#contact-us', label: 'Contact Us', target: '_self' },
    { value: 'privacy-policy', label: 'Privacy Policy', target: '_blank' },
    {
        value: 'terms-conditions',
        label: 'Terms and conditions',
        target: '_blank'
    }
]

const socialIcons = [
    { value: 'https://www.facebook.com/', label: <FacebookIcon /> },
    { value: 'https://twitter.com/', label: <TwitterIcon /> },
    { value: 'https://www.instagram.com/', label: <Instagram /> }
]

export default function Footer() {
    const navigate = useNavigate()
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'))
    const isMedium = useMediaQuery(theme.breakpoints.down('md'))

    return (
        <Box
            sx={{
                textAlign: 'center',
                py: { xs: 2, sm: 5 },
                px: { xs: 2, sm: 3, md: 20 }
            }}
        >
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="left"
                spacing={isSmall ? 4 : isMedium ? 5 : 10}
            >
                <Grid item xs={12} sm={5}>
                    <Box
                        component="img"
                        src={Logo}
                        color="inherit"
                        onClick={() => navigate('/home')}
                        sx={{
                            height: '58px',
                            display: 'block',
                            ml: 0,
                            cursor: 'pointer'
                        }}
                    />
                    <Typography
                        variant="subtitle1"
                        sx={{
                            font: 'normal normal normal 13px/20px Inter',
                            mt: 2,
                            color: 'secondary.light',
                            textAlign: 'left'
                        }}
                    >
                        Special Moments enable you to use the application on
                        various occasions other than just birthdays.
                    </Typography>

                    <Hidden only="xs">
                        <Typography
                            variant="body2"
                            color="text.secondary"
                            align="center"
                            sx={{
                                font: 'normal normal normal 13px/20px Inter',
                                mt: 2,
                                color: 'secondary.light',
                                textAlign: 'left'
                            }}
                        >
                            {'Copyright © '}
                            <Link
                                color="inherit"
                                href={window.location.origin}
                                target="_blank"
                                sx={{
                                    color: 'primary.light',
                                    font: 'normal normal bold 13px/20px Inter',
                                    ml: 0.5
                                }}
                            >
                                Special Moments
                            </Link>{' '}
                            {new Date().getFullYear()}. All Rights Reserved..
                        </Typography>
                    </Hidden>
                </Grid>

                <Grid item xs={6} sm={3} sx={{ textAlign: 'left' }}>
                    <Typography
                        variant="h3"
                        sx={{
                            font: ' normal normal 600 16px/22px Inter'
                        }}
                    >
                        Quick Links
                    </Typography>

                    {navLinks.map((link: any) => (
                        <Link
                            key={link.value}
                            display="block"
                            variant="body1"
                            href={link.value}
                            onClick={(e) => {
                                if(link.requiresLogin){
                                    e.preventDefault();
                                    localStorage.setItem('redirectPath', '/create-event');
                                    navigate('/login')
                                }
                            }}
                            sx={{ my: 1, textDecoration: 'none' }}
                        >
                            <Typography
                                variant="body1"
                                gutterBottom
                                sx={{
                                    font: 'normal normal normal 13px/22px Inter',
                                    color: 'secondary.main',
                                    '&:hover': {
                                        color: 'primary.light'
                                    }
                                }}
                            >
                                {link.label}
                            </Typography>
                        </Link>
                    ))}
                </Grid>

                <Grid item xs={6} sm={3} sx={{ textAlign: 'left' }}>
                    <Typography
                        variant="h3"
                        sx={{
                            font: ' normal normal 600 16px/22px Inter'
                        }}
                    >
                        Follow us on
                    </Typography>

                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {socialIcons.map((link: any) => (
                            <Link
                                key={link.value}
                                display="block"
                                variant="body1"
                                href={link.value}
                                target="_blank"
                                sx={{ my: 1, mr: 1 }}
                            >
                                {link.label}
                            </Link>
                        ))}
                    </Box>

                    {contactNavLink.map((link: any) => (
                        <Link
                            key={link.value}
                            display="block"
                            variant="body1"
                            href={link.value}
                            target={link.target}
                            sx={{ my: 1, textDecoration: 'none' }}
                        >
                            <Typography
                                variant="body1"
                                gutterBottom
                                sx={{
                                    font: 'normal normal normal 13px/22px Inter',
                                    color: 'secondary.main',
                                    '&:hover': {
                                        color: 'primary.light'
                                    }
                                }}
                            >
                                {link.label}
                            </Typography>
                        </Link>
                    ))}
                </Grid>

                <Hidden smUp>
                    <Grid item xs={12}>
                        <Typography
                            variant="body2"
                            color="text.secondary"
                            align="center"
                            sx={{
                                font: 'normal normal normal 13px/20px Inter',
                                color: 'primary.light',
                                textAlign: 'center'
                            }}
                        >
                            {'Copyright © '}
                            <Link
                                color="inherit"
                                href={window.location.origin}
                                target="_blank"
                                sx={{
                                    color: 'primary.light',
                                    font: 'normal normal bold 13px/20px Inter',
                                    ml: 0.5
                                }}
                            >
                                Special Moments
                            </Link>{' '}
                            {new Date().getFullYear()}. All Rights Reserved..
                        </Typography>
                    </Grid>
                </Hidden>
            </Grid>
        </Box>
    )
}
