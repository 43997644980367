import { InputHTMLAttributes } from 'react';

import { Box, TextField } from '@mui/material';

import ErrorValidationMessage from '../ErrorMessage/ErrorValidationMessage';
import Label from '../Label/Label';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    name: string;
    label?: string;
    error?: any;
    register?: any;
    wrapperClass?: string;
    className?: string;
    placeholder?: any;
    xs?: any;
    md?: any;
    multiline?: boolean;
    showLabel?: boolean;
    sx?: any;
    type?: string;
    helperText?: string | undefined;
}

function CustomInput({
    register,
    name,
    error,
    placeholder,
    multiline,
    showLabel,
    helperText,
    sx,
    type,
    ...rest
}: InputProps) {
    return (
        <>
            {showLabel && (
                <Box display="flex" alignItems="start" flexDirection="column">
                    <Label title={placeholder} />
                </Box>
            )}
            <TextField
                {...register(name)}
                {...rest}
                type={type === undefined ? 'text' : type}
                name={name}
                variant="outlined"
                placeholder={placeholder}
                size="small"
                inputProps={{ style: { fontSize: '14px' } }}
                multiline={multiline}
                rows={multiline ? 4 : 1}
                sx={{
                    ...sx,
                    borderRadius: '4px',
                    borderColor: 'secondary.contrastText',
                    color: 'primary.contrastText',
                    width: '100%',
                    '& .MuiInputBase-root': {
                        mt: 0
                    }
                }}
            />
            {helperText && (
                <Box mt={1} sx={{ color: 'red', fontSize: '12px' }}>
                    {helperText}
                </Box>
            )}
            {!showLabel && error && <ErrorValidationMessage message={error} />}
        </>
    );
}

export default CustomInput;
