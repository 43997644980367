import { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Divider, Grid, TextField, Typography, MenuItem, FormHelperText} from '@mui/material';
import dayjs from 'dayjs';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { ReactComponent as SuccessDialogIcon } from '../../../assets/welcome-icon.svg';
import CustomLoadingButton from '../../../components/Shared/Button/CustomLoadingButton';
import CustomizedDialogs from '../../../components/Shared/Dialogs/CustomizedDialogs';
import DialogSuccess from '../../../components/Shared/Dialogs/DialogSuccess';
import ErrorValidationMessage from '../../../components/Shared/ErrorMessage/ErrorValidationMessage';
import CustomDateInput from '../../../components/Shared/Input/CustomDateInput';
import Label from '../../../components/Shared/Label/Label';
import {handleSave} from '../../../helpers/editEventHelper';


const updateEvent = yup.object().shape({
    name: yup.string().required('* required'),
    event_date: yup.string().required('* required'),
    event_type: yup.string().required('* required'),
    deadline: yup.string().required('* required'),
    actual_event_date: yup.string().required('* required')
});

const eventType = [
    { value: 'birthday', label: 'Birthday' },
    { value: 'anniversary', label: 'Anniversary' },
    { value: 'specialDay', label: 'Special Day' },
    { value: '25Anniversary', label: '25th Anniversary' },
    { value: 'sastiPoorthi', label: 'Sasti Poorthi' },
    { value: '50Birthday', label: '50th Birthday' }
]

function EditEvent(props: any) {
    const [showSuccessDialog, setShowSuccessDialog] = useState(false);
    const [btnLoading, setBtnLoading] = useState(false);
    const [ErrorMessage, setErrorMessage] = useState('');
    const [selectedEventType, setSelectedEventType] = useState(props?.event?.event_type || '')

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        control,
        setValue
    } = useForm({
        mode: 'onTouched',
        reValidateMode: 'onBlur',
        resolver: yupResolver(updateEvent)
    });

    useEffect(() => {
        if (props?.event) {
            const defaultValues: any = {
                name: props?.event?.name,
                event_type: props?.event?.event_type,
                deadline: props?.event?.deadline,
                event_date: props?.event?.event_date,
                actual_event_date: props?.event.actual_event_date
            };
            reset({ ...defaultValues });
            setValue('event_date', props?.event?.event_date ? dayjs(props?.event?.event_date) : null);
            setValue('deadline', props?.event?.deadline ? dayjs(props?.event?.deadline) : null);
            setValue('actual_event_date', props?.event?.actual_event_date ? dayjs(props?.event?.actual_event_date) : null);
            setValue('event_type', props?.event?.event_type);
        }
    }, [props?.event, reset, setValue]);



    
    const inputStyle = {
        borderRadius: '4px',
        borderColor: 'secondary.contrastText',
        color: 'primary.contrastText',
        width: '100%'
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Typography
                variant="h6"
                sx={{
                    color: 'custom.gray'
                }}
            >
                Edit
            </Typography>
            <Divider sx={{ my: 1 }} />

            <Typography
                sx={{
                    font: 'normal normal 300 11px/14px Inter',
                    color: 'secondary.main'
                }}
            >
                Edit your changes below and save changes.
            </Typography>

            <Grid
                container
                component="main"
                direction="row"
                justifyContent="space-between"
                spacing={2}
                sx={{ mt: 2, mb: 0 }}
            >
                <Grid item xs={12} sm={6}>
                    <Box display="flex" alignItems="center">
                        <Label title="What’s the event?" />
                    </Box>

                    <TextField
                        {...register('event_type')}
                        type="text"
                        name="event_type"
                        variant="outlined"
                        value={selectedEventType}
                        onChange={(e) => setSelectedEventType(e.target.value)}
                        select
                        placeholder="What’s the event?"
                        size="small"
                        sx={inputStyle}
                    >
                    {eventType.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>

                    )) }
                    </TextField>

                </Grid>

                <Grid item xs={12} sm={6}>
                    <Box display="flex" alignItems="center">
                        <Label title="Celebrating the event for?" />
                        {errors.name && errors.name.message && (
                            <ErrorValidationMessage
                                message={errors.name.message}
                            />
                        )}
                    </Box>
                    <TextField
                        {...register('name')}
                        type="text"
                        name="name"
                        variant="outlined"
                        placeholder="Celebrating the event for?"
                        size="small"
                        sx={inputStyle}
                    />
                    <FormHelperText sx={{ color: 'red', fontSize: '12px' }}>
                        {ErrorMessage[0] === "Celebrating the event name cannot be start with spaces" ? ErrorMessage[0] : ''}
                    </FormHelperText>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <CustomDateInput
                        name="event_date"
                        type="text"
                        showLabel
                        placeholder="When will this event be celebrated?"
                        error={errors?.event_date?.message}
                        xs={12}
                        md={6}
                        control={control}
                        value={props?.event?.event_date} 
                    />
                    
                </Grid>

                <Grid item xs={12} sm={6}>
                    <CustomDateInput
                        name="actual_event_date"
                        type="text"
                        showLabel
                        placeholder="When is the event Planned to be Published?"
                        error={errors?.actual_event_date?.message}
                        xs={12}
                        md={6}
                        control={control}
                        value={props?.event?.actual_event_date} 
                    />
                    <FormHelperText sx={{ color: 'red', fontSize: '12px' }}>
                        {ErrorMessage[0] === "Event Publish Date Cannot be after the event celebrated date" ? ErrorMessage[0] : ''}
                    </FormHelperText>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <CustomDateInput
                        name="deadline"
                        type="text"
                        showLabel
                        placeholder="What is the Target Date to send the Memories?"
                        error={errors?.deadline?.message}
                        xs={12}
                        md={6}
                        control={control}
                        value={props?.event?.deadline} 
                    />
                    <FormHelperText sx={{ color: 'red', fontSize: '12px' }}>
                        {ErrorMessage[0] === "Event Target Date cannot be after the event celebrated date" ? ErrorMessage[0] : ''}
                    </FormHelperText>
                </Grid>

            </Grid>

            <Box
                display="flex"
                justifyContent="end"
                alignItems="center"
                sx={{
                    height: 100,
                    minWidth: '100%',
                    mb: 3
                }}
            >
                <CustomLoadingButton
                    onClick={handleSubmit((data) => handleSave(data, props, setBtnLoading, setErrorMessage, setShowSuccessDialog))}
                    loading={btnLoading}
                    name="Update"
                    width="auto"
                />
            </Box>
            <CustomizedDialogs
                open={showSuccessDialog}
                onClose={() => setShowSuccessDialog(false)}
                header="Event Update Successfully"
                message="The event details that you edited are updated."
                continueBtn="View Event"
                icon={SuccessDialogIcon}
                component={DialogSuccess}
            />
        </Box>
    );
}

export { EditEvent };
