import dayjs from 'dayjs';

import { eventService } from '../services/event.service'

export const handleSave = async (data: any,
    props: any,
    setBtnLoading: (loading: boolean) => void, 
    setErrorMessage: (message: string) => void, 
    setShowSuccessDialog: (dialog: boolean) => void) => {
    setBtnLoading(true);
    setErrorMessage('');
    const request = {
        name: data?.name,
        event_type: data?.event_type,
        deadline: dayjs(data?.deadline).format('YYYY-MM-DD'),
        event_date: dayjs(data?.event_date).format('YYYY-MM-DD'),
        actual_event_date: dayjs(data?.actual_event_date).format('YYYY-MM-DD')
    };

    try {
        await eventService.eventUpdate(props?.event.event_id, request);
        setBtnLoading(false);
        setShowSuccessDialog(true);
        props?.updateEventDataCallBack();
    } catch (error: any) {
        if (error?.response && error?.response.data && error?.response.data.non_field_errors) {
            setErrorMessage(error.response.data.non_field_errors);
        } else {
            setErrorMessage('An error occurred while updating the event. Please try again later.');
        }
        setBtnLoading(false);
    }
};
